.container {
    padding: 0 0 20px;
    [class~="ant-card-head"]{
        color: #475569 !important;
    }
    [class~="ant-card-body"]{
        overflow-x: auto;
    }
}

.cflex {
    display: flex;
    flex-direction: column;
}

.rowflex{
    display: flex;
    align-items: center;
    width: 100%;
}

.card1 {
    height: 350px;
    width: 50%;
    max-width: 800px;
    overflow-y: auto;
    margin-right: 20px;
    .contain1 {
        display: flex;
        .left {
            flex: 1;
            position: relative;
            .tcontain{
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .t1 {
                font-size: 20px;
                color: #666;
                line-height: 20px;
            }

            .t2 {
                font-size: 48px;
                margin-top: 20px;
                font-weight: bold;
                color: #333;
                line-height: 48px;
                word-break: break-all;
            }

            .t3 {
                font-size: 14px;
                margin-top: 6px;
                color: #999;
                line-height: 14px;
            }
        }

        .right {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-between;

            .item {
                width: 200px;
                margin-bottom: 10px;
                position: relative;
                color: #fff;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &:last-child{
                    margin-bottom: 0;
                }
                .top{
                    display: flex;
                    align-items: center;
                }
                .icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    margin-right: 10px;
                    background: #fff;
                    font-size: 18px;
                }

                .label {
                    font-size: 16px;
                }

                .value {
                    font-size: 20px;
                    width: 100%;
                    word-break: break-all;
                }
            }
        }
    }
}

.card2 {
    flex: 1;
    margin-right: 20px;
    min-height: 350px;
    [class~="ant-card-body"]{
        padding: 0;
    }
    // [class~='ant-table-wrapper'] >tr>td{
    //     background: #f00 !important;
    // }
}

.create {
    display: flex;
    height: 350px;
    position: relative;
    background: #e5f5ff;
    margin-right: 5px;
}

.card3 {
    flex: 1;
    height: 350px;
}

.rightbox {
    width: 250px;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    border-radius: 20px;
    padding: 20px;

    .h1 {
        font-size: 20px;
        color: #006efd;
        position: relative;
    }

    .h2 {
        font-size: 14px;
        margin-top: 8px;
        color: #006efd;
        position: relative;
    }

    .bg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 200px;
        background: url('../../Images/create.png') no-repeat;
        background-size: 100% 100%;
    }

    .plus {
        border:2px solid #006efd;
        color: #006efd;
        padding: 5px 20px;
        display: inline-block;
        border-radius: 5px;
        margin-top: 15px;
        position: relative;
        z-index: 10;
        cursor: pointer;
    }
}

.card4{
    height: 350px;
    flex: 1;
    margin-right: 20px;
}

.card5{
    height: 350px;
    flex: 1;
}
