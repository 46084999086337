.antimage {
    margin-right: 15px;
    // display: inline-block;
    margin-bottom: 8px;
    width: 175px;
    height: 175px;
    position: relative;
    display: inline-block;
    vertical-align: top;

    &.antimagew100 {
        width: 100px;
        height: 100px;

        .iconsw100 {
            font-size: 15px !important;
        }

        .mask .url {
            font-size: 8px;
            bottom: 10px;
            white-space: nowrap;
        }
    }

    &:hover {
        .mask {
            display: inline-block;
        }
    }

    .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        display: none;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;

        .url {
            font-size: 13px;
            color: white;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            bottom: 20px;
            white-space: nowrap;
        }
    }
}

.upload {
    :global {
        .ant-upload.ant-upload-select-picture-card {
            width: 175px;
            height: 175px;
            margin-right: 0;
        }
        .ant-upload.ant-upload-select{
            width: 175px !important;
            height: 175px !important;
            margin-right: 0;
        }
    }
}

.uploadw100 {
    :global {
        .ant-upload.ant-upload-select-picture-card {
            width: 100px;
            height: 100px;
            margin-right: 0;
        }
    }
}

.clearfix:after {
    content: '';
    clear: both;
    display: block;
}

.whitespace {
    white-space: nowrap;
}

.newTableth{
    font-weight:600;
    color: #475569;
    th{
        background: #e5f5ff !important;
        border-radius: 0 !important;
        text-align: start;
        padding: 16px 16px;
    }
}

.newTablethR{
    font-weight:600;
    color: #475569;
    th{
        background: #e5f5ff !important;
        text-align: start;
        padding: 16px 16px;
    }
}