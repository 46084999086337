$bodyBackground: #eff3f9;

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 16px;
}

.flex{
  display: flex;
  align-items: center;
}

.content {
  flex: 1 !important;
  background: $bodyBackground;
  overflow-y: auto;
  // display: flex;
  // flex-direction: column;
  padding: 0 20px ;
}
.sider{
  overflow: auto;
  padding: 20px 0 15px 0;
  background: #fff !important;
}
.qsstitle{
  color: #464646;
  font-weight: bold;
  margin-left: 5px;
}

.botlayout {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0);
  display: flex;
}

.logobox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 30px 20px;
  font-size: 20px;
}

.logoboxv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 30px 20px;
  .logo{
    margin-bottom: 10px;
  }
}

.logo {
  background: url("../../Images/slico.png") no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  position:relative;
}

.title {
  color: #fff;
  font-size: 28px;
  position: absolute;
  left: 230px;
  top: 18px;
}

.userbox {
  display: flex;
  .item{
    margin-right: 15px;
  } 
}

.Tag {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(to bottom,
      rgba(248, 248, 248, 1),
      rgba(242, 242, 242, 1));
  color: #666;
  border-radius: 5px 5px 0 0;
  outline: none;
  border: none;
  padding: 0 30px;
  position: relative;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 0;
  vertical-align: bottom;
}

.TagSelected {
  background: linear-gradient(to bottom,
      rgba(250, 255, 253, 1),
      rgba(224, 255, 251, 1));
  color: #000;
}

.closeicon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.footer{
  width: 100%;
  background: #fff !important;
  border-radius: 20px 20px 0 0;
  justify-content: center;
  display: flex;
  align-items: center;
  .footer_title{
    color: #666;
    font-size: 16px;
  }
  .footer_tails{
    font-size: 12px;
  }
  .company{
    color: #006efd;
    font-size: 12px;
    margin: 0 5px 0 10px;
  }
}