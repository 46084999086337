.background {
    background: #e5f0fb;
    width: 100%;
    height: 100%;
    position: relative;
}

.box {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);
    width: 350px;
    padding: 50px 20px;
    background: #fff;
    color: #666666;
    position: absolute;
    border-radius: 10px;
    top: 140px;
    right: 100px;
    animation: rightToLeft .75s linear 0s 1 normal;

}

@keyframes rightToLeft {
    0% {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.loginbtn {
    background: #006efd;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: calc(30% - 10px);
    background: #c0c0c0;
    overflow: hidden;
}

.topbox {
    color: #fff;
    padding: 100px 150px 0;
    color: #333;
    font-size: 32px;
    display: flex;
    flex-direction: column;

    .toptitle {
        color: #006efd;
        margin-bottom: 20px;
    }
}

.logo {
    background: url('../../Images/singleelogo.png') no-repeat;
    background-size: 100% 100%;
    width: 224px;
    height: 55px;
}

.title {
    flex: 1;
}

.bg {
    width: 50vw;
    height: 60vh;
    background: url('../../Images/backbg.png') no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 50px;
    bottom: 0px;
}