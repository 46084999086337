$blackColor: #475569;
$bodyBackground: #919aa3;

.ant-menu-light {
  color: #666 !important;
}

.ant-menu .ant-menu-item {
  border-radius: 0px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  color: $blackColor;
  background: #fff;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background: #fff;
}

// .ant-menu-item-active {
//   background-color: rgba($color: #8edea1, $alpha: 0.5) !important;
//   color: #009235 !important;
// }
.ant-menu .ant-menu-submenu-title {
  border-radius: 0px !important;
  margin: 0 !important;
  width: 100% !important;
}


.ant-menu-submenu-selected>.ant-menu-submenu-title {
  // color: #009235 !important;
  color: #006efd !important;
  // background-color: rgba($color: #8edea1, $alpha: 0.2) !important;
  background-color: #eef6f5 !important;

  &::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    // background: #009235;
    background: #006efd;
    bottom: 0;
    left: 0;
  }
}

.ant-menu-item-selected {

  color: #006efd !important;
  // background-color: rgba($color: #000000, $alpha: 0) !important;
}