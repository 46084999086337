html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.clearfix::after {
  content: "";
  clear: both;
  display: block;
}
.ant-table{
  border-radius: 8px 8px 0 0 !important;
}
.formModal {
  width: 90%;
}

.prevForm{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px 10px;
  background: #fff;
  border-radius: 10px 10px 0 0;
}
.prevForm div[class~="ant-form-item"]{
  margin-bottom: 10px;
}
.funcs{
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  width: 50px;
  min-height: 160px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  right:-50px;
  transform: translateY(-50%);
  padding: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-size: 28px;
  animation: slideInFromRight 0.5s ease 0.25s;
  animation-fill-mode: forwards;
}
.funcs>*{
  margin-bottom: 10px;
  cursor: pointer;
}
.funcs .btn_disabled{
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

@keyframes slideInFromRight {
  0% {
    right: -50px;
  }
  100% {
   right:0px;
  }
}
